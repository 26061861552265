import {createSlice} from "@reduxjs/toolkit";
import userPhoto from '../assets/icons/user.svg';

const initialState = {
    isAuth: false,
    user: {
        name: '',
        photo: userPhoto,
        id: null,
    },
};

export const accountSlice = createSlice({
    name: 'accountPage',
    initialState,
    reducers: {
        setAuth(state, action) {
            state.isAuth = action.payload;
        },
        setUserInfo(state, action) {
            state.user = {
                name: action.payload.first_name,
                photo: action.payload.photo ? action.payload.photo : userPhoto,
                id: action.payload.id
            }
        }
    }
})

export default accountSlice.reducer;

export const {
    setAuth,
    setUserInfo
} = accountSlice.actions;