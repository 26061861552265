import React from "react";
import styles from './Link.module.scss'

const Link = () => {

    return (
        <a className={styles.link} href="#">Посилання на чек</a>
    )
}

export default Link;