import React from "react";
import styles from './Shadow.module.scss';
import {useAppSelector} from "../../../hooks/redux";
import {getIsActivePopup} from "../../../store/selectors";

const Shadow = () => {
    const isShadow = useAppSelector(getIsActivePopup);

    return (
        <span className={isShadow ? styles.shadowActive : styles.shadowhide}>
        </span>
    )
}

export default Shadow;