import React from "react";
import styles from './Details.module.scss';
import arr from '../../../../assets/icons/arr_up.svg';
import DetailsInput from "./Input/DetailsInput";


const Details = () => {
    return (
        <div className={styles.details}>
            <div className={styles.details__titleBlock}>
                <h3>Детальна інформація</h3>
            </div>
            <DetailsInput/>
        </div>
    )
}

export default Details;