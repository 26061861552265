import React from "react";
import styles from '../ProductItem.module.scss';
import FabricSlider from "../../../UserPage/FabricSlider";
import ImgPlaceholderIcon from "../../../../assets/icons/placeholderIcon.svg";
import {CopyToClipboard} from "react-copy-to-clipboard";
import copyIcon from "../../../../assets/icons/copy4.svg";
import IconWarning from "../../../../assets/img/warning.svg";

const OpenItem = ({item}) => {

    let {images, stock, code, type, content, additionalOptions, prices} = item;

    /*let textareaValue = content.join('\n\n');*/

    let textareaValue = content.map((item, index) => {
        return prices && prices.length > index
            ? `${item} - ${prices[index]}`
            : item;
    }).join('\n\n');

    return (
        <React.Fragment>
            {images.length ? <FabricSlider images={images}/> :
                <div className={styles.imgPlaceholder}>
                    <img src={ImgPlaceholderIcon} alt=""/>
                    <CopyToClipboard text={'COPY'}>
                        <button>
                            <img src={copyIcon} alt=""/>
                        </button>
                    </CopyToClipboard>
                </div>}
            <div className={images.length ? `${styles.inputRow} ${styles.withSlider}` : styles.inputRow}>
                <div>
                    <label htmlFor="product_input1">Код тканини</label>
                    <div className={styles.inputWrap}>
                        <input type="text" id="product_input1" value={code} readOnly/>
                        {!stock && <img src={IconWarning} alt=""/>}
                    </div>
                </div>
                <div>
                    <label htmlFor="product_input2">Тип комплекту</label>
                    <input type="text" id="product_input2" value={type} readOnly/>
                </div>
            </div>
            {!stock && <p className={styles.warning}>Тканини немає в наявності</p>}
            <div className={styles.textareaWrap}>
                <label>Склад комплекту</label>
                <textarea readOnly value={textareaValue}></textarea>
            </div>
            <label htmlFor="product_options">Додаткові опції:</label>
            <input type="text" id="product_options" value={additionalOptions} readOnly/>
            <button className={styles.changeBtn}><span>Змінити комлект</span></button>
        </React.Fragment>
    )
}

export default OpenItem;