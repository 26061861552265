import React from "react";
import styles from './Callback.module.scss';

const CallbackWorkTime = () => {
    return (
        <div className={styles.workTime}>
            <div className={styles.workTime__left}>
                <span>ПН-ПТ</span>
                <p>9:00-18:00</p>
            </div>
            <div className={styles.workTime__right}>
                <span>CБ-НД</span>
                <p>Вихідний</p>
            </div>
        </div>
    )
}

export default CallbackWorkTime;