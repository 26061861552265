import React from "react";
import styles from './NovaPoshtaWeight.module.scss'
import {setIsActivePopup} from "../../../../store/main-slice";
import {useAppDispatch} from "../../../../hooks/redux";
import GlobalPopup from "../../../Common/GlobalPopup/GlobalPopup";

const NovaPoshtaWeight = ({weight, setWeight}) => {
    const dispatch = useAppDispatch();

    return (
        <>
            <div className={styles.weight}>
                <button type='button' onClick={() => dispatch(setIsActivePopup(true))}>
                    {weight ? <span>{weight}</span> : <span className={styles.weightText}>Об'ємна вага, кг</span>}
                </button>
            </div>
            <GlobalPopup>
                <span>calc</span>
            </GlobalPopup>
        </>
    )
}

export default NovaPoshtaWeight;