import React, {useEffect, useState} from "react";
import styles from "./UkrPoshtaIndex.module.scss";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import {getAccessToken, getCities} from "../../../../api/api";
import '../DeliveryUKR.scss';


const UkrPoshtaIndex = ({index, setIndex}) => {
    const [indexes, setIndexes] = useState([]);
    const [value, setValue] = useState('');

    let timeout;

    useEffect(() => {
        if (value.length > 2) {
            clearTimeout(timeout);
            timeout = setTimeout(() => {
                getAccessToken().then((token) => {
                    getCities(token, value).then((response) => {
                        if (response && response.indexes) {
                            const changerArray = response.indexes.map((item) => {
                                return (
                                    {label: item.city, id: item.city_ref, area: item.area, settlement_type: item.settlement_type}
                                )
                            })
                            setIndexes(changerArray)
                        }
                    })
                })
            }, 500);
            return () => {
                clearTimeout(timeout);
            };
        }
    }, [value]);


    return (
        <div className={styles.ukrPoshtaIndex}>
            <Autocomplete
                disablePortal
                options={indexes}
                value={index}
                noOptionsText="No cities"
                onKeyUp={(e) => setValue(e.target.value)}
                onChange={(event, newValue) => setIndex(newValue)}
                renderInput={(params) => <TextField {...params} label="Поштовий індекс *" />}
            />
        </div>
    )
}

export default UkrPoshtaIndex;