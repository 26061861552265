import React from "react";
import BtnGroup from "./Button1/Button";
import Btn from "./Button2/Button";

const Payment = () => {

    return (
        <div>
            <h3>Оплатити онлайн</h3>
            <BtnGroup/>
            <Btn/>
        </div>
    )
}

export default Payment;