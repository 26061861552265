import './App.scss';
import React, {useEffect, useState} from "react";
import {getIsAuth} from "./store/selectors";
import {useAppDispatch, useAppSelector} from "./hooks/redux";
import ClientPage from "./components/ClientPage/ClientPage";
import Shadow from "./components/Common/Shadow/Shadow";
import AuthorizedPage from "./components/AuthorizedPage/AuthorizedPage";
import {getAccessToken, getUserInfo} from "./api/api";
import {setAuth, setUserInfo} from "./store/account-slice";

function App() {
    const isAuth = useAppSelector(getIsAuth);
    const dispatch = useAppDispatch();
    const [accessToken, setAccessToken] = useState(null)

    getAccessToken().then((token) => {
        if (!accessToken && token && token.length > 1) {
            setAccessToken(token)
        }
    })

    useEffect(() => {
        if (accessToken) {
            getUserInfo(accessToken).then((result) => {
                if (result && result.first_name) {
                    dispatch(setUserInfo(result));
                    dispatch(setAuth(true));
                } else {
                    setAuth(false);
                }
            })
        }
    }, [accessToken])


    return (
        <div className='wrapper'>
            {isAuth
                ? <AuthorizedPage/>
                : <ClientPage/>
            }
            <Shadow/>
        </div>
    );
}

export default App;