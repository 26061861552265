import React, {useRef, useState} from "react";
import styles from './DateTimeField.module.scss';
import './DateTimeField.scss'
import arrow from '../../../assets/icons/arrowDownGray.svg';
import {setIsActivePopup} from "../../../store/main-slice";
import {useAppDispatch} from "../../../hooks/redux";
import GlobalPopup from "../../Common/GlobalPopup/GlobalPopup";
import {DemoContainer, DemoItem} from '@mui/x-date-pickers/internals/demo';
import {AdapterDayjs} from '@mui/x-date-pickers/AdapterDayjs';
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider';
import {StaticDatePicker} from '@mui/x-date-pickers/StaticDatePicker';
import GlobalCalculator from "../../Common/GlobalCalculator/GlobalCalculator";


const DateTimeField = ({date, setDate, setPopup, popup}) => {
    const dispatch = useAppDispatch();

    const onShowPopup = (value) => {
        setPopup(value);
        setTimeout(() => {
            dispatch(setIsActivePopup(true))
        }, 30)
    }

    const onSelectDate = (value) => {
        const formatedDate = `${value.$D < 10 ? `0${value.$D}` : `${value.$D}`}.${value.$M + 1 < 10 ? `0${value.$M + 1}` : `${value.$M + 1}`}.${value.$y}`
        setDate(formatedDate);
        setTimeout(() => {
            dispatch(setIsActivePopup(false))
        }, 200)
    }

    const [hours, setHours] = useState('');
    const [minutes, setMinutes] = useState('');
    const [currentFocus, setCurrentFocus] = useState(null);
    const [lastEditedInput, setLastEditedInput] = useState('');
    const hoursInputRef = useRef(null);
    const minutesInputRef = useRef(null);

    const handleHoursChange = (e) => {
        let value = e.target.textContent;
        let potentialNewValue = hours + value;
        if (potentialNewValue.length <= 2 && /^\d{1,2}$/.test(potentialNewValue)) {
            let numericValue = parseInt(potentialNewValue, 10);
            if (numericValue <= 23) {
                setHours(potentialNewValue);
                setLastEditedInput('hours');
                if (potentialNewValue.length === 2) {
                    minutesInputRef.current.focus();
                }
            }
        }
    }

    const handleHoursDelete = () => {
        let newValue = hours.slice(0, hours.length - 1);
        setHours(newValue);
    }

    const handleMinutesChange = (e) => {
        let value = e.target.textContent;
        let potentialNewValue = minutes + value;
        if (potentialNewValue.length <= 2 && /^\d{1,2}$/.test(potentialNewValue)) {
            let numericValue = parseInt(potentialNewValue, 10);
            if (numericValue <= 59) {
                setMinutes(potentialNewValue);
                setLastEditedInput('minutes');
            }
        }
    }

    const handleMinutesDelete = () => {
        let newValue = minutes.slice(0, minutes.length - 1);
        setMinutes(newValue);
    }

    const handleChange = (e) => {
        if ((currentFocus === 'hours' && hours.length !== 2) || (!currentFocus && hours.length !== 2)) {
            handleHoursChange(e)
        } else  if (currentFocus === 'minutes' || (!currentFocus && hours.length === 2) || (currentFocus === 'hours' && hours.length === 2)) {
            handleMinutesChange(e);
        }
    }

    const handleDelete = () => {
        if (currentFocus === 'hours' || (currentFocus === 'minutes' && minutes.length === 0)) {
            handleHoursDelete();
            hoursInputRef.current.focus();
        } else  if (currentFocus === 'minutes' && minutes.length !== 0) {
            handleMinutesDelete();
        } else if (!currentFocus) {
            if (lastEditedInput === 'hours') {
                handleHoursDelete();
            } else if (lastEditedInput === 'minutes' && minutes.length !== 0) {
                handleMinutesDelete();
            } else if (lastEditedInput === 'minutes' && minutes.length === 0) {
                handleHoursDelete();
            }
        }
    }

    return (
        <>
            <div className={styles.dateTimeField}>
                <button type='button' onClick={() => onShowPopup('date')}>
                    <span>{date}</span>
                    <img src={arrow} alt=""/>
                </button>
                <button type='button' onClick={() => onShowPopup('time')}>
                    <span>Час оплати</span>
                    <img src={arrow} alt=""/>
                </button>
            </div>
            {popup && popup === 'date' && (
                <GlobalPopup>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DemoContainer
                            components={[
                                'StaticDatePicker',
                            ]}
                        >
                            <DemoItem>
                                <StaticDatePicker onChange={(newValue) => onSelectDate(newValue)}/>
                            </DemoItem>
                        </DemoContainer>
                    </LocalizationProvider>
                </GlobalPopup>
            )}
            {popup && popup === 'time' && (
                <GlobalPopup>
                    <GlobalCalculator handleChange={handleChange} handleDelete={handleDelete}>
                        <div className={styles.percentInputWrap}>
                            <input id='percent_discount' name="percent_discount" value={hours}
                                   className={'baseInput'} type="text" readOnly  onFocus={() => setCurrentFocus('hours')} ref={hoursInputRef}/>
                            <span>:</span>
                            <input id='percent_discount' name="percent_discount" value={minutes}
                                   className={'baseInput'} type="text" readOnly  onFocus={() => setCurrentFocus('minutes')} ref={minutesInputRef}/>
                        </div>
                    </GlobalCalculator>
                </GlobalPopup>
            )}
        </>
    )
}

export default DateTimeField;