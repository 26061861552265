import React, {useEffect, useState} from "react";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import {getAccessToken, getDepartment} from "../../../../api/api";


const NovaPoshtaDepartments = ({city, setDepartment, department}) => {
    const [departments, setDepartments] = useState([]);

    useEffect(() => {
        if (city && city.id) {
            getAccessToken().then((token) => {
                getDepartment(token, city.id).then((response) => {
                    if (response && response.warehouses) {
                        const changerArray = response.warehouses.map((item) => {
                            return (
                                {
                                    label: item.address,
                                    address_ref: item.address_ref,
                                    city: item.city,
                                    city_ref: item.city_ref,
                                    index: item.index,
                                    max_weight_allowed: item.max_weight_allowed,
                                    max_weight_allowed_place: item.max_weight_allowed_place,
                                    max_weight_allowed_total: item.max_weight_allowed_total,
                                    number: item.number
                                }
                            )
                        })
                        setDepartments(changerArray)
                    }
                })
            })
        }
    }, [city])

    return (
        <>
            <Autocomplete
                disablePortal
                options={departments}
                value={department}
                noOptionsText="No cities"
                onChange={(event, newValue) => setDepartment(newValue)}
                renderInput={(params) => <TextField {...params} label="Номер відділення" />}
            />
        </>
    )
}

export default NovaPoshtaDepartments;