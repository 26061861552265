import React from "react";
import styles from './Status.module.scss';
import logo from "../../../assets/img/logoEgo.svg";
import Details from "./Details/Details";
import InvoiceInfo from "./InvoiceInfo/InvoiceInfo";
import List from "./List/List";
import Price from "./Price/Price";
import Payment from "./Payment/Payment";
import PaymentInfo from "./PaymentInfo/PaymentInfo";
import Link from "./Link/Link";


const Status = () => {
    return (
        <div className={styles.status}>
            <div className={styles.status__logo}>
                <img src={logo} alt=""/>
            </div>
            <h2>Замовлення № 091273</h2>
            <p className={styles.status__date}>20.06.2024</p>
            <Details/>
            <InvoiceInfo/>
            <List/>
            <Price/>
            <Payment/>
            <PaymentInfo/>
            <Link/>
        </div>
    )
}

export default Status;