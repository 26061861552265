import React, {useEffect, useState} from "react";
import styles from './Main.module.scss';
import UserHeader from "./UserHeader/UserHeader";
import MainTabs from "./MainTabs/MainTabs";
import searchIcon from '../../../assets/icons/search.svg';
import {useAppDispatch, useAppSelector} from "../../../hooks/redux";
import {getOrderList, getUser} from "../../../store/selectors";
import Order from "./Order/Order";
import {getAccessToken, getOrders} from "../../../api/api";
import {setOrders} from "../../../store/main-slice";

const Main = () => {
    const orderList = useAppSelector(getOrderList);
    const {id} = useAppSelector(getUser);
    const [ordersFilter, setOrdersFilter] = useState('my');
    const dispatch = useAppDispatch();

    useEffect(() => {
        if (id) {
            getAccessToken().then((token) => {
                getOrders(token, ordersFilter === 'my' || ordersFilter === 'favorite' ? id : null, ordersFilter === 'favorite' ? true : null)
                    .then((result) => dispatch(setOrders(result)));
            })
        }
    }, [ordersFilter, id])

    return (
        <div className={styles.main}>
            <UserHeader />
            <div className={styles.main__search}>
                <input type="search" placeholder={'Пошук'} className={'baseInput'}/>
                <img src={searchIcon} alt=""/>
            </div>
            <MainTabs orders={ordersFilter} setOrders={setOrdersFilter} />
            {orderList && orderList.length > 0 && (
                <div className={styles.orderWrapper}>
                    {orderList.map((item, index) => (
                        <Order key={index} item={item} />
                    ))}
                </div>

            )}
        </div>
    )
}

export default Main;