import React from "react";
import styles from './Order.module.scss';
import items from '../../../../assets/icons/itemsIcon.svg'
import arrow from '../../../../assets/icons/arrowRight.svg'


const Order = ({item}) => {
    // console.log(item)
    return (
        <div className={styles.order}>
            <p className={styles.order__header}>
                <span>#{item.id}</span>
                <span>{item.date}</span>
            </p>
            <div className={styles.content}>
                <div className={styles.content__boxes}>
                    <div className={styles.box}>
                        <span className={styles.box__role}>Замовник</span>
                        {item && item.customer && (
                            <p className={styles.box__text}>{item.customer.username}</p>
                        )}
                        {item && item.customer && (
                            <p className={styles.box__text}>{item.customer.last_name} {item.customer.first_name} {item.customer.middle_name}</p>
                        )}
                        {item && item.customer && item.customer.phone && (
                            <p className={styles.box__phone}>{item.customer.phone}</p>
                        )}
                    </div>
                    <div className={styles.box}>
                        <span className={styles.box__role}>Менеджер</span>
                        <p className={styles.box__text}>{item.managerNickname}</p>
                        {item && item.manager && (
                            <p className={styles.box__text}>{item.manager.username}</p>
                        )}
                        {item && item.manager && (
                            <p className={styles.box__text}>{item.manager.last_name} {item.manager.first_name} {item.manager.middle_name}</p>
                        )}

                    </div>
                </div>
                <div className={styles.content__count}>
                    <img src={items} alt=""/>
                    <p>{item.products ? item.products : 0} товарів</p>
                    <p>{item.sets ? item.sets : 0} комплектів</p>
                </div>
            </div>
            <div className={styles.order__footer}>
                <span>{item && item.payment && item.payment.amount ? item.payment.amount : 0} грн</span>
                <img src={arrow} alt=""/>
            </div>
        </div>
    )
}

export default Order;