import React from "react";
import styles from './GlobalCalculator.module.scss';
import IconDelete from "../../../assets/icons/delete.svg";

const GlobalCalculator = ({children, handleChange, handleDelete}) => {
    return (
        <div className={styles.pinForm}>
            <div className={styles.form}>
                {/*<input id={'login'} name={'login'} value={inputValue} className={'baseInput'} type="text" maxLength={4} readOnly/>*/}
                {children}
            </div>
            <div className={styles.grid}>
                <button type={'button'} onClick={handleChange}>7</button>
                <button type={'button'} onClick={handleChange}>8</button>
                <button type={'button'} onClick={handleChange}>9</button>
                <button type={'button'} onClick={handleChange}>4</button>
                <button type={'button'} onClick={handleChange}>5</button>
                <button type={'button'} onClick={handleChange}>6</button>
                <button type={'button'} onClick={handleChange}>1</button>
                <button type={'button'} onClick={handleChange}>2</button>
                <button type={'button'} onClick={handleChange}>3</button>
                <button type={'button'} onClick={handleChange}>0</button>
                <button type={'button'} onClick={handleDelete}><img src={IconDelete} alt=""/></button>
            </div>
        </div>
    )
}

export default GlobalCalculator;