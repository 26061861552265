import React, {useState} from "react";
import styles from './Order.module.scss';
import {setIsActivePopup} from "../../../store/main-slice";
import {useAppDispatch} from "../../../hooks/redux";
import GlobalPopup from "../../Common/GlobalPopup/GlobalPopup";
import GlobalCalculator from "../../Common/GlobalCalculator/GlobalCalculator";


const DiscountField = ({discountType, setDiscountType, popup, setPopup}) => {
    const dispatch = useAppDispatch();

    const onChangeDiscount = (value) => {
        setDiscountType(value);
        setPopup(value);
        setTimeout(() => {
            dispatch(setIsActivePopup(true))
        }, 30)
    }

    const [percentInputValue, setPercentInputValue] = useState('');
    const [UAHInputValue, setUAHInputValue] = useState('');

    const handlePercentChange = (e) => {
        let value = e.target.textContent;
        let potentialNewValue = percentInputValue + value;
        potentialNewValue = parseInt(potentialNewValue, 10);
        if (potentialNewValue <= 100) {
            setPercentInputValue(potentialNewValue.toString());
        }
    }

    const handlePercentDelete = () => {
        let newValue = percentInputValue.slice(0, percentInputValue.length - 1);
        setPercentInputValue(newValue);
    }

    const handleUAHChange = (e) => {
        let value = e.target.textContent;
        if (UAHInputValue.length < 4) {
            setUAHInputValue(prev => prev + value);
        }
    }

    const handleUAHDelete = () => {
        let newValue = UAHInputValue.slice(0, UAHInputValue.length - 1);
        setUAHInputValue(newValue);
    }

    return (
        <div className={styles.discountField}>
            <span>Знижка:</span>
            <div className={styles.discountField__wrapper}>
                <div className={'charger'}>
                    <span>Онлайн</span>
                    <input type="radio" name='discountType' checked={discountType === 'percent'}
                           onChange={() => onChangeDiscount('percent')}/>
                    <p>%</p>
                </div>
                <div className={'charger'}>
                    <span>на Р/Р</span>
                    <input type="radio" name='discountType' checked={discountType === 'uah'}
                           onChange={() => onChangeDiscount('uah')}/>
                    <p>грн</p>
                </div>
            </div>
            {popup && popup === 'percent' && (
                <GlobalPopup>
                    <GlobalCalculator handleChange={handlePercentChange} handleDelete={handlePercentDelete}>
                        <div className={styles.percentInputWrap}>
                            <input id='percent_discount' name="percent_discount" value={percentInputValue}
                                   className={'baseInput'} type="text" readOnly/><span>%</span>
                        </div>
                    </GlobalCalculator>
                </GlobalPopup>
            )}
            {popup && popup === 'uah' && (
                <GlobalPopup>
                    <GlobalCalculator handleChange={handleUAHChange} handleDelete={handleUAHDelete}>
                        <div className={styles.UAHInputWrap}>
                            <input id='uah_discount' name="uah_discount" value={UAHInputValue}
                                   className={'baseInput'} type="text" readOnly/><span>&nbsp;грн</span>
                        </div>
                    </GlobalCalculator>
                </GlobalPopup>
            )}
        </div>
    )
}

export default DiscountField;