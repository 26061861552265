import React from "react";
import styles from './Auth.module.scss';
import logo from '../../../assets/img/logoEgo.svg'
import GlobalPopup from "../../Common/GlobalPopup/GlobalPopup";
import AuthForm from "./AuthForm/AuthForm";

import {useAppDispatch} from "../../../hooks/redux";
import {setIsActivePopup} from "../../../store/main-slice";


const Auth = () => {
    const dispatch = useAppDispatch();

    return (
        <div className={styles.auth}>
            <div className={styles.auth__logo}>
                <img src={logo} alt=""/>
                <div className={styles.action}>
                    <button onClick={() => dispatch(setIsActivePopup(true))}>
                        <span>Вхід</span>
                    </button>
                </div>
            </div>
            <GlobalPopup>
                <AuthForm />
            </GlobalPopup>
        </div>
    )
}

export default Auth;