import React from "react";
import styles from './Storage.module.scss';
import searchIcon from "../../../assets/icons/search.svg";
import StorageItem from "./StorageItem/StorageItem";

const Storage = () => {

    let storageInfo = [
        {'name': 'Ковдра', 'balance': 72, 'img': null},
        {'name': 'Подушка', 'balance': 8, 'img': null},
        {'name': 'Плед', 'balance': 60, 'img': null},
        {'name': 'Наматрасник', 'balance': 60, 'img': null},
        {'name': 'Електро-простинь', 'balance': 60, 'img': null}
    ]

    return (
        <div className={styles.storage}>
            <div className={styles.storage__search}>
                <input type="search" placeholder={'Пошук'} className={'baseInput'}/>
                <img src={searchIcon} alt=""/>
            </div>
            {
                storageInfo.map(({name, balance, img}, index) => <StorageItem key={index} name={name} balance={balance} img={img}/>)
            }
        </div>
    )
}

export default Storage;