import React from "react";
import styles from './OrderStatus.module.scss';
import OrderInfoBlock from "../../Common/OrderInfoBlock/OrderInfoBlock";
import Img from "../../../assets/img/slide.png";
import ProductItem from "../../Common/ProductItem/ProductItem";
import OrderActions from "./OrderActions";

const OrderStatus = () => {
    const invoice = 'ТТН 89343473647382487';
    const post = 'Нова пошта 25';
    const link = '#';
    const order = '091273';
    const orderStatus = 'Очікує відправки';
    const totalPrice = '8000';

    let productInfo = [
        {
            images: [Img, Img],
            code: 'А: N015',
            stock: 1,
            type: 'Двоспальний',
            content: ['Наволочка (з Вушками)', 'Підковдра (Стандарт)', 'Простирадло (на резинці)'],
            additionalOptions: ['Подарункова упаковка'],
            prices: ['1800 грн', '2200 грн', '1850 грн']
        },
        {
            images: [],
            code: 'А: N015',
            stock: 1,
            type: 'Сімейний',
            content: ['Наволочка (з Вушками)', 'Підковдра (Стандарт)', 'Простирадло (на резинці)'],
            additionalOptions: ['Подарункова упаковка'],
            prices: ['1800 грн', '2200 грн', '1850 грн']
        }, {
            name: 'Електропростинь',
            images: [],
            code: 'А: N015',
            stock: 1,
            type: '',
            content: ['Наволочка (з Вушками)', 'Підковдра (Стандарт)', 'Простирадло (на резинці)'],
            additionalOptions: ['Подарункова упаковка'],
            prices: ['1800 грн', '2200 грн', '1850 грн']
        }]


    return (
        <div className={styles.orderStatus}>
            <h2>Замовлення № {order}</h2>
           <OrderInfoBlock invoice={invoice} link={link} post={post}/>
            <div className={styles.statusBar}>
                <span>{orderStatus}</span>
            </div>
            {productInfo.map((el, index) => <ProductItem item={el} key={index}/>)}
            <OrderActions/>
            <div className={styles.priceBlock}>
                <span>Ціна замовлення:</span>
                <span className={styles.totalPrice}>{totalPrice} грн</span>
            </div>
            <br/>
            <br/>
        </div>
    )
}

export default OrderStatus;