import React, {useState} from "react";
import styles from './CallbackForm.module.scss';
import {setIsActivePopup} from "../../../../store/main-slice";
import {useAppDispatch} from "../../../../hooks/redux";
import GlobalPopup from "../../../Common/GlobalPopup/GlobalPopup";
import PopupCheckboxes from "../../../Common/PopupCheckboxes/PopupCheckboxes";


const CallbackForm = () => {
    const dispatch = useAppDispatch();
    const [reason, setReason] = useState([]);
    const [name, setName] = useState('');
    const [phone, setPhone] = useState('');

    const chooseList = [
        'Брак товару', 'Оптове замовлення', 'Питання оплати'
    ];

    const onSubmit = () => {
        console.log({
            name: name,
            phone: phone,
            reason: reason
        })
    }

    return (
        <div className={styles.CallbackForm}>
            <input type="text" placeholder={'Ім’я'} value={name} onChange={(e) => setName(e.target.value)}/>
            <input type="number" placeholder={'Номер телефону'} value={phone}
                   onChange={(e) => setPhone(e.target.value)}/>
            <button className={styles.buttonField} onClick={() => dispatch(setIsActivePopup(true))}
                    type='button'>
                <span>{'Звернення у зв\'язку з'}</span>
            </button>
            <button onClick={() => onSubmit()} type='submit' className={styles.action}
                    disabled={!name.length || !phone.length || !reason.length }>
                <span>Замовии дзвінок</span>
            </button>
            <GlobalPopup>
                <PopupCheckboxes values={reason} setValues={setReason} chooseList={chooseList} />
            </GlobalPopup>
        </div>
    )
}

export default CallbackForm;