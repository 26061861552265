import {createSlice} from "@reduxjs/toolkit";
import Pagin1 from "../assets/img/pagin1.png";
import Pagin2 from "../assets/img/pagin2.png";
import Pagin3 from "../assets/img/pagin3.png";

const initialState = {
    isActivePopup: false,
    orders: [
        {
            id: 28014,
            date: '13.06.24',
            userNickname: 'MaxPower',
            userName: 'Коваль Максим Миколайович',
            userPhone: '097 528 55 22',
            managerNickname: 'XVetalino',
            managerName: 'Мазур Віталій Володимирович',
            managerPhone: '093 255 55 22',
            products: 3,
            sets: 2,
            value: 4805,
        },
        {
            id: 28013,
            date: '12.06.24',
            userNickname: 'MaxPower',
            userName: 'Коваль Максим Миколайович',
            userPhone: '097 528 55 22',
            managerNickname: 'XVetalino',
            managerName: 'Мазур Віталій Володимирович',
            managerPhone: '093 255 55 22',
            products: 6,
            sets: 1,
            value: 2450,
        },
    ],
    fabrics: ['Котон', 'Сатин', 'Бавовна', 'Льон', 'Шовк'],
    fabricsAll: [
        {
            image: '',
            fabricType: 'Котон',
            number: 'AZ_004',
            remainderA: 8,
            remainderB: 40,
            serial: ['A'],
        },
        {
            image: '',
            fabricType: 'Сатин',
            number: 'AZ_004',
            remainderA: 20,
            remainderB: 40,
            serial: ['A', 'B'],
        },
        {
            image: '',
            fabricType: 'Сатин',
            number: 'AZ_004',
            remainderA: 1,
            remainderB: 2,
            serial: ['A', 'B'],
        },
        {
            image: '',
            fabricType: 'Сатин',
            number: 'AZ_004',
            remainderA: 4,
            remainderB: 11,
            serial: ['B'],
        },
    ],
    userProduct : {
        images: [Pagin1, Pagin2, Pagin3],
        id: '300SH (бязь)'
    }
};

export const mainSlice = createSlice({
    name: 'mainPage',
    initialState,
    reducers: {
        setIsActivePopup(state, {payload}) {
            state.isActivePopup = payload;
        },
        setOrders(state, {payload}) {
            state.orders = payload;
        }
    },
})

export default mainSlice.reducer;

export const {
    setIsActivePopup,
    setOrders,
} = mainSlice.actions;
