import React from "react";
import styles from './StorageItem.module.scss';
import IconPlaceholder from "../../../../../assets/img/storage_item_icon_placeholder.svg";
import {CopyToClipboard} from "react-copy-to-clipboard";
import copyIcon from "../../../../../assets/icons/copy.svg";
import plus from "../../../../../assets/icons/plus.svg";

const StorageItem = ({name, balance, img, sizeCount, colorCount}) => {

    let basicCount = 4;

    return (
        <div className={styles.storageItem}>
            <div className={styles.infoWrap}>
                <div className={styles.titleWrap}>
                    <span className={styles.iconWrap}>
                         {img ? <img src={img} alt=""/> : <img src={IconPlaceholder} alt=""/>}
                    </span>
                    <div>
                        <h3>{name}</h3>
                        <p className={parseInt(balance) < 10 ? styles.balanceWarning : ''}>Залишок {balance}</p>
                    </div>
                </div>
              {/*  <CopyToClipboard text={name}>
                    <button>
                        <img src={copyIcon} alt=""/>
                    </button>
                </CopyToClipboard>*/}
                <div className={styles.addWrap}>
                    <button>
                        <img src={plus} alt=""/>
                    </button>
                    <span>Додати</span>
                </div>
            </div>
            <div className={styles.optionsWrap}>
                <div>
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                    {parseInt(sizeCount) > basicCount && <span className={styles.additional}>+{parseInt(sizeCount) - basicCount}</span>}
                </div>
                <div>
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                    {parseInt(colorCount) > basicCount && <span className={styles.additional}>+{parseInt(colorCount) - basicCount}</span>}
                </div>
            </div>
        </div>
    )
}

export default StorageItem;