import React from "react";
import styles from './FabricsContent.module.scss';
import {CopyToClipboard} from "react-copy-to-clipboard";
import copyIcon from "../../../../assets/icons/copy.svg";

const FabricsContent = ({fabricsAll}) => {
    return (
        <ul className={styles.fabricsContent}>
            {fabricsAll && fabricsAll.length > 0 && (
                fabricsAll.map((item, index) => (
                    <li className={styles.fabric} key={index}>
                        <div className={styles.fabric__left}>
                            <div className={styles.fabric__image}>
                                <img src="" alt=""/>
                            </div>
                            <div className={styles.fabric__info}>
                                <h5>{item.fabricType}</h5>
                                <p>{item.number}</p>
                                <span className={styles.fabric__remainder}>Залишок <span className={item.remainderA <= 10 ? styles.red : null}><b>A</b>{`(${item.remainderA}м)`}</span>, <span className={item.remainderB <= 10 ? styles.red : null}><b>B</b>{`(${item.remainderB}м)`}</span></span>
                            </div>
                        </div>
                        <div className={styles.fabric__right}>
                            <div className={styles.fabric__category}>
                                {item.serial && (
                                    item.serial.map((element, index) => (
                                        <span key={index}>{element}</span>
                                    ))
                                )}
                            </div>
                            <CopyToClipboard text={'COPY'}>
                                <button>
                                    <img src={copyIcon} alt=""/>
                                </button>
                            </CopyToClipboard>
                        </div>
                    </li>
                ))
            )}
        </ul>
    )
}

export default FabricsContent;