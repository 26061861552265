import React, {useState} from "react";
import styles from './DeliveryUKR.module.scss';
import {useFormik} from "formik";
import UkrPoshtaCities from "./UkrPoshtaCities/UkrPoshtaCities";
import UkrPoshtaRegion from "./UkrPoshtaRegion/UkrPoshtaRegion";
import UkrPoshtaArea from "./UkrPoshtaArea/UkrPoshtaArea";
import UkrPoshtaIndex from "./UkrPoshtaIndex/UkrPoshtaIndex";
import DeliveryIPay from "../DeliveryNP/DeliveryIPay/DeliveryIPay";
import {CopyToClipboard} from "react-copy-to-clipboard";
import copyIcon from "../../../assets/icons/copy.svg";

const DeliveryUKR = () => {
    const [city, setCity] = useState('');
    const [region, setRegion] = useState('');
    const [area, setArea] = useState('');
    const [index, setIndex] = useState('');
    const [isIPay, setIsIPay] = useState(false);

    const {setSubmitting, handleSubmit, isSubmitting, handleChange, values, resetForm} = useFormik({
        initialValues: {
            firstNameUKR: '',
            lastNamUKR: '',
            phoneUKR: '',
            street: '',
            house: '',
            apartment: '',
            declaredValueUKR: '',
            deliveryCostUKR: '',
            ttnUKR: '',
            commentUKR: '',
        },

        onSubmit: (values) => {
            setSubmitting(false);
            console.log(values)
            resetForm();
        },
    });

    return (
        <div className={`${styles.deliveryUKR} deliveryUKR`}>
            <form onSubmit={handleSubmit}>
                <div className={styles.deliveryUKR__content}>
                    <div className={styles.deliveryUKR__row}>
                        <input
                            placeholder={'І`мя Отримувача'}
                            className={'baseInput'}
                            id="firstNameUKR"
                            name="firstNameUKR"
                            type="text"
                            onChange={handleChange}
                            value={values.firstNameUKR}
                        />
                        <input
                            placeholder={'Прізвище Отримувача'}
                            className={'baseInput'}
                            id="lastNamUKR"
                            name="lastNamUKR"
                            type="text"
                            onChange={handleChange}
                            value={values.lastNamUKR}
                        />
                    </div>
                    <div>
                        <input
                            placeholder={'Телефон Отримувача'}
                            className={'baseInput'}
                            id="phoneUKR"
                            name="phoneUKR"
                            type="text"
                            onChange={handleChange}
                            value={values.phoneUKR}
                        />
                    </div>
                    <UkrPoshtaCities city={city} setCity={setCity} />
                    <UkrPoshtaRegion region={region} setRegion={setRegion} />
                    <UkrPoshtaArea area={area} setArea={setArea} />
                    <UkrPoshtaIndex index={index} setIndex={setIndex} />
                    <div>
                        <input
                            placeholder={'Вулиця'}
                            className={'baseInput'}
                            id="street"
                            name="street"
                            type="text"
                            onChange={handleChange}
                            value={values.street}
                        />
                    </div>
                    <div className={styles.deliveryUKR__row}>
                        <input
                            placeholder={'Будинок'}
                            className={'baseInput'}
                            id="house"
                            name="house"
                            type="number"
                            onChange={handleChange}
                            value={values.house}
                        />
                        <input
                            placeholder={'Квартира'}
                            className={'baseInput'}
                            id="apartment"
                            name="apartment"
                            type="number"
                            onChange={handleChange}
                            value={values.apartment}
                        />
                    </div>
                    <DeliveryIPay isIPay={isIPay} setIsIPay={setIsIPay} />
                    <div>
                        <input
                            placeholder={'Оголошена вартість'}
                            className={'baseInput'}
                            id="declaredValueUKR"
                            name="declaredValueUKR"
                            type="number"
                            onChange={handleChange}
                            value={values.declaredValueUKR}
                        />
                    </div>
                    <div>
                        <input
                            placeholder={'Орієнтовна Вартість Доставки'}
                            className={'baseInput'}
                            id="deliveryCostUKR"
                            name="deliveryCostUKR"
                            type="number"
                            onChange={handleChange}
                            value={values.deliveryCostUKR}
                        />
                    </div>
                    <div className={styles.transportNumber}>
                        <input
                            placeholder={'ТТН'}
                            className={'baseInput'}
                            id="ttnUKR"
                            name="ttnUKR"
                            type="number"
                            onChange={handleChange}
                            value={values.ttnUKR}
                        />
                        <CopyToClipboard text={values.ttnUKR} className={styles.copyButton}>
                            <button type='button'>
                                <img src={copyIcon} alt=""/>
                            </button>
                        </CopyToClipboard>
                    </div>
                    <div>
                        <textarea
                            cols="30"
                            rows="10"
                            placeholder={'Коментар'}
                            id="commentUKR"
                            name="commentUKR"
                            onChange={handleChange}
                            value={values.commentUKR}
                        />
                    </div>
                    <div className={styles.action}>
                        <button className={'globalBtn'} type='submit'>
                            <span>Зберегти</span>
                        </button>
                    </div>
                </div>
            </form>
        </div>
    )
}

export default DeliveryUKR;