import React from "react";
import styles from './Price.module.scss'

const PriceInfo = () => {
    return (
        <div className={styles.price}>
           <p className={styles.oldPrice}>8200</p>
            <div className={styles.price__info}>
                <p>Ціна замовлення:</p>
                <span className={styles.price__number}>8000 грн</span>
            </div>
            <div className={styles.discount}>
                <p>Знижка: <span className={styles.discount__percent}>10%</span></p>
                <span className={styles.discount__number}>-200 грн</span>
            </div>
        </div>
    )
}

export default PriceInfo;