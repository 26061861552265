import React from "react";
import styles from './Order.module.scss';


const PayCharger = ({payType, setPayType}) => {
    return (
        <div className={styles.payChanger}>
            <span>Тип оплати</span>
            <div className={styles.payChanger__wrapper}>
                <div className={'charger'}>
                    <span>Онлайн</span>
                    <input type="radio" name='payCharger' checked={payType === 'online'}
                           onChange={() => setPayType('online')} />
                    <p>Онлайн</p>
                </div>
                <div className={'charger'}>
                    <span>на Р/Р</span>
                    <input type="radio" name='payCharger'  checked={payType === 'p/p'}
                           onChange={() => setPayType('p/p')} />
                    <p>на Р/Р</p>
                </div>
                <div className={'charger'}>
                    <span>Післяоплата</span>
                    <input type="radio" name='payCharger'  checked={payType === 'daterPay'}
                           onChange={() => setPayType('daterPay')} />
                    <p>Післяоплата</p>
                </div>
            </div>
        </div>
    )
}

export default PayCharger;