import React from "react";
import styles from './PaymentInfo.module.scss'
import PaidButton from "./PaidBtn/Button";
import AttachButton from "./AttachBtn/AttachBtn";
import SendButton from "./SendBtn/SendBtn";

const PaymentInfo = () => {

    return (
        <div>
          <h3>Повідомити про оплату</h3>
            <div className={styles.btnGroup}>
                <PaidButton/>
                <AttachButton/>
                <SendButton/>
            </div>

        </div>
    )
}

export default PaymentInfo;