import React, {useState} from "react";
import styles from './Order.module.scss';
import paste from '../../../assets/icons/pasteIcon.svg';
import {useFormik} from 'formik';
import PayCharger from "./PayCharger";
import DateTimeField from "../DateTimeField/DateTimeField";
import StatusPay from "./StatusPay";
import DiscountField from "./DiscountField";
import UrgentlyField from "./UrgentlyField";

const Order = () => {
    const id = 'ID9813402';
    const today = new Date();
    const yyyy = today.getFullYear();
    let mm = today.getMonth() + 1; // Months start at 0!
    let dd = today.getDate();
    if (dd < 10) dd = '0' + dd;
    if (mm < 10) mm = '0' + mm;
    const formattedToday = dd + '.' + mm + '.' + yyyy;

    const [payType, setPayType] = useState('online');
    const [date, setDate] = useState('Дата оплати');
    const [discountType, setDiscountType] = useState('percent')
    const [isUrgently, setIsUrgently] = useState(false);
    const [popup, setPopup] = useState(null);

    const {setSubmitting, handleSubmit, isSubmitting, handleChange, values, resetForm} = useFormik({
        initialValues: {
            userUrl: '',
            nickName: '',
            firstName: '',
            lastName: '',
            middleName: '',
            clientPhone: '',
            sumPay: '',
            discountValue: '',
            sum: '',
            comment: '',
        },

        onSubmit: (values) => {
            setSubmitting(false);
            console.log(values)
            resetForm();
        },
    });

    return (
        <div className={styles.order}>
            <form onSubmit={handleSubmit}>
                <h2 className={styles.order__title}>Замовлення</h2>
                <div className={styles.order__content}>
                    <p className={styles.order__header}>
                        <span>{id}</span>
                        <span>{formattedToday}</span>
                    </p>
                    <div className={styles.form}>
                        <div className={styles.form__userUrl}>
                            <input
                                placeholder={'User URL'}
                                className={'baseInput'}
                                id="userUrl"
                                name="userUrl"
                                type="text"
                                onChange={handleChange}
                                value={values.userUrl}
                            />
                            <button>
                                <img src={paste} alt=""/>
                            </button>
                        </div>
                        <div>
                            <input
                                placeholder={'Нік'}
                                className={'baseInput'}
                                id="nickName"
                                name="nickName"
                                type="text"
                                onChange={handleChange}
                                value={values.nickName}
                            />
                        </div>
                        <div className={styles.form__name}>
                            <input
                                placeholder={'І`мя'}
                                className={'baseInput'}
                                id="firstName"
                                name="firstName"
                                type="text"
                                onChange={handleChange}
                                value={values.firstName}
                            />
                            <input
                                placeholder={'Прізвище'}
                                className={'baseInput'}
                                id="middleName"
                                name="middleName"
                                type="text"
                                onChange={handleChange}
                                value={values.middleName}
                            />
                        </div>
                        <div>
                            <input
                                placeholder={'По батькові'}
                                className={'baseInput'}
                                id="nickName"
                                name="nickName"
                                type="text"
                                onChange={handleChange}
                                value={values.nickName}
                            />
                        </div>
                        <div>
                            <input
                                placeholder={'Телефон замовника'}
                                className={'baseInput'}
                                id="clientPhone"
                                name="clientPhone"
                                type="text"
                                onChange={handleChange}
                                value={values.clientPhone}
                            />
                        </div>
                        <div>
                            <input
                                placeholder={'Сума передоплати(післяоплата)'}
                                className={'baseInput'}
                                id="sumPay"
                                name="sumPay"
                                type="number"
                                onChange={handleChange}
                                value={values.sumPay}
                            />
                        </div>
                        <PayCharger payType={payType} setPayType={setPayType}/>
                        <DateTimeField date={date} setDate={setDate} setPopup={setPopup} popup={popup}/>
                        <StatusPay setPopup={setPopup} popup={popup}/>
                        <DiscountField discountType={discountType} setDiscountType={setDiscountType} popup={popup} setPopup={setPopup} />
                        <div className={styles.closedInput}>
                            <input
                                placeholder={'Сума знижки(авто)'}
                                className={'baseInput'}
                                id="discountValue"
                                name="discountValue"
                                type="number"
                                onChange={handleChange}
                                value={values.discountValue}
                                readOnly
                            />
                        </div>
                        <div className={styles.closedInput}>
                            <input
                                placeholder={'Сума'}
                                className={'baseInput'}
                                id="sum"
                                name="sum"
                                type="number"
                                onChange={handleChange}
                                value={values.sum}
                                readOnly
                            />
                        </div>
                        <UrgentlyField setIsUrgently={setIsUrgently} isUrgently={isUrgently}/>
                        <div>
                            <textarea
                                placeholder={'Коментар до замовлення'}
                                className={'baseInput'}
                                id="comment"
                                name="comment"
                                onChange={handleChange}
                                value={values.comment}
                            />
                        </div>
                    </div>
                    <div className={styles.action}>
                        <button type={'submit'} className={'globalBtn'}>
                            <span>На виробництво</span>
                        </button>
                    </div>
                </div>
            </form>
        </div>
    )
}

export default Order;