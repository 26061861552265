import React from "react";
import styles from './OrderActions.module.scss';
import copyIcon from "../../../assets/icons/copy2.svg";
import {CopyToClipboard} from "react-copy-to-clipboard";

const OrderActions = () => {

    return (
        <div className={styles.orderActions}>
            <div className={styles.row}>
                <CopyToClipboard text={'Оплата за товар'} className={styles.copyButton}>
                    <button>
                        <img src={copyIcon} alt=""/>
                        <span>Cкопіювати все</span>
                    </button>
                </CopyToClipboard>
                <a href="#" className={styles.checkLink}>
                    Посилання на чек
                </a>
            </div>
            <div className={styles.row}>
                <a href="#" className={styles.orderLink}>
                    Посилання на замовлення
                </a>
                <button className={styles.prodLink}>
                    <span>На виробництво</span>
                </button>
            </div>
        </div>
    )
}

export default OrderActions;