import React from "react";
import styles from './ChoosePopup.module.scss';
import FabricChooseItem from "./FabricChooseItem";

const ChoosePopup = ({fabricsList, handleChange, selectedFabric}) => {
    return (
        <div className={styles.chooserPopup}>
            {fabricsList && fabricsList.length > 0 && (
                fabricsList.map((item, index) => (
                    <FabricChooseItem key={index} item={item} handleChange={handleChange} selectedFabric={selectedFabric} />
                ))
            )}

        </div>
    )
}

export default ChoosePopup;