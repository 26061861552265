import React from "react";
import styles from './SendBtn.module.scss';

const SendButton = () => {

    return (
        <button className={styles.btn}>
           <span>Відправити</span>
        </button>
    )
}

export default SendButton;