import React, {useState} from "react";
import styles from './DetailsInput.module.scss';


const DetailsInput = () => {
    const [phoneDigits, setPhoneDigits] = useState('');

    const handleChange = (e) => {
        const value = e.target.value;
        const valid = /^[0-9]*$/;
        if ((value === '' || valid.test(value))) {
            setPhoneDigits(value);
        }
    }

    return (
        <div className={styles.inputBlock}>
            <input type="text" name="order_digits" value={phoneDigits}
                   placeholder="Введіть 4 останніх цифри телефону отримувача"
                   onChange={(e) => handleChange(e)} maxLength="4"/>
            <button><span>OK</span></button>
        </div>
    )
}

export default DetailsInput;