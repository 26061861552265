import React from "react";
import styles from '../ProductItem.module.scss';
import ImgPlaceholder from "../../../../assets/img/placeholder.svg";

const ClosedItem = ({item}) => {

    let {images, type} = item;

    return (
        <React.Fragment>
            <div className={styles.closedWrap}>
                <img src={!images.length? ImgPlaceholder:images[0]} alt=""/>
                <div>
                    <p className={styles.closedWrap__title}>{type? 'Тип комплекту' : 'Товар зі складу'}</p>
                    <p>{type? 'Сімейний' : 'Електро-простинь'}</p>
                </div>

            </div>
        </React.Fragment>
    )
}

export default ClosedItem;