import React, {useState} from "react";
import styles from './ProductItem.module.scss';
import IconArr from "../../../assets/icons/arrowDown.svg";
import OpenItem from "./OpenItem/OpenItem";
import ClosedItem from "./ClosedItem/ClosedItem";

const ProductItem = ({item, isOpen}) => {

    const [open, setOpen] = useState(isOpen);

    const toggleOpen = () => {
        setOpen(prevOpen => !prevOpen);
    }

    return (
        <div className={styles.productItem}>
            {open? <OpenItem item={item}/> : <ClosedItem item={item}/>}
            <div className={styles.panel} onClick={toggleOpen}>
                <button>
                    <img src={IconArr} alt="" className={open? styles.arrUp : ''}/>
                </button>
            </div>
        </div>
    )
}

export default ProductItem;