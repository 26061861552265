import React from "react";
import styles from './ProductActions.module.scss';
import copyIcon from "../../../../assets/icons/copy2.svg";
import {CopyToClipboard} from "react-copy-to-clipboard";
import {setIsActivePopup} from "../../../../store/main-slice";
import {useAppDispatch} from "../../../../hooks/redux";
import GlobalPopup from "../../../Common/GlobalPopup/GlobalPopup";
import StoragePopup from "../StoragePopup/StoragePopup";

const ProductActions = () => {
    const dispatch = useAppDispatch();

    return (
        <div className={styles.productActions}>
            <div className={styles.row}>
                <button className={styles.addItem} onClick={() => dispatch(setIsActivePopup(true))}>
                    <span>Додати товар зі складу</span>
                </button>
                <button className={styles.addSet}>
                    <span>Додати комплект</span>
                </button>
            </div>
            <div className={styles.row}>
                <CopyToClipboard text={'Оплата за товар'} className={styles.copyButton}>
                    <button>
                        <img src={copyIcon} alt=""/>
                        <span>Скопіювати посилання</span>
                    </button>
                </CopyToClipboard>
                <button className={styles.prodLink}>
                    <span>На виробництво</span>
                </button>
            </div>
            <GlobalPopup topPopup>
                <StoragePopup/>
            </GlobalPopup>
        </div>
    )
}

export default ProductActions;