const baseUrl = 'https://panel.egodevelopment.pp.ua/api/v1/';

export const setTokens = (access, refresh) => {
    try {
        localStorage.setItem('accessEgoToken', access);
        localStorage.setItem('refreshEgoToken', refresh);
    } catch (e) {
        console.log(e);
    }
}

export const setNewAccessToken = async (token) => {
    try {
        localStorage.setItem('accessEgoToken', token)
        return 'ok';
    } catch (e) {
        console.log(e);
    }
}

export const getAccessToken = async () => {
    try {
        return await localStorage.getItem('accessEgoToken');
    } catch (e) {
        console.log(e);
    }
};

export const refreshAccessToken = async () => {
    try {
        const refreshToken = localStorage.getItem('refreshEgoToken');
        const response = await fetch(`${baseUrl}users/token/refresh/`, {
            method: "POST",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                refresh: refreshToken,
            })
        });
        const newAccessToken = response.json();
        newAccessToken.then((res) => {
            setNewAccessToken(res.access).then((res) => {
                if (res) {
                    return newAccessToken;
                }
            })
        })
        return newAccessToken;
    } catch (error) {
        console.log(error);
    }
}

export const login = async (name, password) => {
    try {
        const response = await fetch(`${baseUrl}users/token/`, {
            method: "POST",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                username: name,
                password: password,
            })
        });
        const result = response.json();
        result.then((res) => {
            if (res && res.access && res.refresh) {
                setTokens(res.access, res.refresh);
            } else {
                return result;
            }
        })
        return result;
    } catch (error) {
        console.log(error);
    }
}

export const getUserInfo = async (token) => {
    try {
        const response = await fetch(`${baseUrl}users/`, {
            method: "GET",
            headers: {
                'Authorization': `Bearer ${token}`,
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
        });
        if (response && response.statusText === 'Unauthorized') {
            return refreshAccessToken().then((res) => {
                if (res) {
                    return getUserInfo(res.access).then((result) => {
                        return result;
                    });
                }
            });
        } else {
            return response.json();
        }

    } catch (error) {
        console.log(error);
    }
}

export const getOrders = async (token, id = null, isFavourite = null) => {
    try {
        const response = await fetch( id && isFavourite ? `${baseUrl}orders/?manager=${id}&is_favourite=${isFavourite}`
            : id && !isFavourite ? `${baseUrl}orders/?manager=${id}`
                : `${baseUrl}orders/` , {
            method: "GET",
            headers: {
                'Authorization': `Bearer ${token}`,
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
        });
        if (response && response.statusText === 'Unauthorized') {
            return refreshAccessToken().then((res) => {
                if (res) {
                    return getOrders(res.access, id, isFavourite).then((result) => {
                        return result;
                    });
                }
            });
        } else {
            return response.json();
        }

    } catch (error) {
        console.log(error);
    }
}


export const getCities = async (token, value) => {
    try {
        const response = await fetch( `${baseUrl}delivery/nova_poshta/location/city/?city=${value}` , {
            method: "GET",
            headers: {
                'Authorization': `Bearer ${token}`,
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
        });
        if (response && response.statusText === 'Unauthorized') {
            return refreshAccessToken().then((res) => {
                if (res) {
                    return getCities(res.access, value).then((result) => {
                        return result;
                    });
                }
            });
        } else {
            return response.json();
        }

    } catch (error) {
        console.log(error);
    }
}

export const getDepartment = async (token, value) => {
    try {
        const response = await fetch( `${baseUrl}delivery/nova_poshta/location/warehouse/?city_ref=${value}` , {
            method: "GET",
            headers: {
                'Authorization': `Bearer ${token}`,
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
        });
        if (response && response.statusText === 'Unauthorized') {
            return refreshAccessToken().then((res) => {
                if (res) {
                    return getDepartment(res.access, value).then((result) => {
                        return result;
                    });
                }
            });
        } else {
            return response.json();
        }

    } catch (error) {
        console.log(error);
    }
}
