import React, {useState} from "react";
import styles from './Fabrics.module.scss';
import searchIcon from "../../../assets/icons/search.svg";
import {useAppSelector} from "../../../hooks/redux";
import {getFabrics, getFabricsAll} from "../../../store/selectors";
import FabricChooser from "./FabricChooser/FabricChooserList";
import FabricsContent from "./FabricsContent/FabricsContent";

const Fabrics = () => {
    const fabricsList = useAppSelector(getFabrics);
    const fabricsAll = useAppSelector(getFabricsAll);

    const [selectedFabric, setSelectedFabric] = useState(fabricsList && fabricsList.length ? [fabricsList[0]] : []);

    const handleChange = (value) => {
        if (selectedFabric.length) {
            const isItemValue = selectedFabric.find((item) => item === value);

            if (isItemValue) {
                const newArray = selectedFabric.filter((item) => item !== value);
                setSelectedFabric([...newArray]);
            } else {
                setSelectedFabric([...selectedFabric, value])
            }
        } else {
            const newArray = [`${value}`];
            setSelectedFabric(newArray)
        }
    }

    return (
        <div className={styles.fabrics}>
            <div className={styles.fabrics__search}>
                <input type="search" placeholder={'Пошук'} className={'baseInput'}/>
                <img src={searchIcon} alt=""/>
            </div>
            <FabricChooser handleChange={handleChange} fabricsList={fabricsList} selectedFabric={selectedFabric}
                           setSelectedFabric={setSelectedFabric}
            />
            <FabricsContent fabricsAll={fabricsAll} />
        </div>
    )
}

export default Fabrics;