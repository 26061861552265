import React from "react";
import styles from './Callback.module.scss';
import logo from "../../../assets/img/logoEgo.svg";
import CallbackForm from "./CallbackForm/CallbackForm";
import CallbackWorkTime from "./CallbackWorkTime";


const Callback = () => {
    return (
        <div className={styles.callback}>
            <div className={styles.callback__logo}>
                <img src={logo} alt=""/>
            </div>
            <h2>Замовити дзвінок</h2>
            <CallbackForm/>
            <h2>Години роботи менеджерів</h2>
            <CallbackWorkTime/>
            <p className={styles.callback__text}>
                Вона популяризувалась в 60-их роках минулого сторіччя завдяки виданню зразків шрифтів Letraset, які
                містили уривки з Lorem Ipsum, і вдруге - нещодавно завдяки програмам комп'ютерного верстування на кшталт
                Aldus Pagemaker, які використовували різні версії Lorem Ipsum.
            </p>
        </div>
    )
}

export default Callback;