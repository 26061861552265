import React from "react";
import styles from "./DeliveryIPay.module.scss";


const DeliveryIPay = ({isIPay, setIsIPay}) => {
    return (
        <div className={styles.iPay}>
            <span>Хто оплачує Доставку </span>
            <div className={styles.iPay__customCheckbox}>
                <input name={'ipay'} type={'checkbox'} onChange={() => setIsIPay(!isIPay)} />
                <span/>
                <label htmlFor={'ipay'}>Наша доставка</label>
            </div>
        </div>
    )
}

export default DeliveryIPay;