import React from "react";
import styles from '../Payment.module.scss';
import Privat from '../../../../../assets/img/paymentBtn.png';


const Button = () => {

    return (
        <button className={styles.paymentBtn}>
            <img src={Privat} alt=""/>
        </button>
    )
}

export default Button;