import React from "react";
import styles from './GlobalPopup.module.scss';
import closeIcon from '../../../assets/icons/closeCross.svg';
import {useAppDispatch, useAppSelector} from "../../../hooks/redux";
import {getIsActivePopup} from "../../../store/selectors";
import {setIsActivePopup} from "../../../store/main-slice";

const GlobalPopup = ({children, topPopup}) => {
    const activePopup = useAppSelector(getIsActivePopup);

    const dispatch = useAppDispatch();

    const popupClass = activePopup
        ? `${styles.globalPopupActive} ${topPopup ? styles.topPopup : ''}`
        : styles.globalPopup;

    return (
        <div className={popupClass}>
            <div className={styles.header}>
                <p className={styles.header__title}>Title</p>
                <div className={styles.header__close} onClick={() => dispatch(setIsActivePopup(false))}>
                    <span>Закрити</span>
                    <button>
                        <img src={closeIcon} alt="" />
                    </button>
                </div>
            </div>
            <div className={styles.content}>
                {children}
            </div>
        </div>
    )
}

export default GlobalPopup;