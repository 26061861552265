import React from "react";
import styles from './Fabric.module.scss';
import ImgPlaceholder from '../../assets/img/FabricImgPlaceholder.png';
import FabricSlider from "./FabricSlider";
import {useAppSelector} from "../../hooks/redux";
import {getUserProductId, getUserProductImages} from "../../store/selectors";

const Fabric = () => {
    let imgLength = 3;
    let userProductId = useAppSelector(getUserProductId);
    let images = useAppSelector(getUserProductImages);
    return (
        <div className={styles.fabric}>
            {images.length? <FabricSlider images={images}/> : <div className={styles.imgPlaceholder}>
            <img src={ImgPlaceholder} alt=""/>
        </div>}
            <div className={images.length ? `${styles.tableWrap} ${styles.withSlider}` : styles.tableWrap}>
                <h3>{userProductId}</h3>
                <div className={styles.table}>
                    <div><span>Назва</span></div>
                    <div><span>Вартість</span></div>
                    <div><span>Доступно(шт).</span></div>
                    <div><span>1.5</span></div>
                    <div><span>1800 грн</span></div>
                    <div><span>11 шт.</span></div>
                    <div><span>2.0</span></div>
                    <div><span>1900 грн</span></div>
                    <div><span>16 шт.</span></div>
                    <div><span>Євро</span></div>
                    <div><span>2400 грн</span></div>
                    <div><span>12 шт.</span></div>
                    <div><span>Євро Maxi</span></div>
                    <div><span>2800 грн</span></div>
                    <div><span>8 шт.</span></div>
                    <div><span>Сімейний</span></div>
                    <div><span>2300 грн</span></div>
                    <div><span>10 шт.</span></div>
                </div>
                <p>Доступно в компанії</p>
            </div>
        </div>
    )
}

export default Fabric;