import styles from "./CheckBox.module.scss";

const CheckBox = ({name, value, setChecked}) => {

    const handleChange = (e) => {
        setChecked(e.target.checked);
    }

    return (
        <div className={styles.wrap}>
            <input type="checkbox" className={styles.checkbox} name={name} value={value} checked={!!value}
                   onChange={handleChange} id="payment_checkbox"/>
            <label htmlFor="payment_checkbox">Я вже оплатив</label>
        </div>
    );
};


export default CheckBox;