import React from "react";
import Item from "./Item/Item";


const List = () => {
    const listInfo = [{name: "Item 1"}, {name: "Item 2"}, {name: "Item 3"}];

    return (
        <div>
            <h3>Товари в замовленні</h3>
            <div>
                {
                    listInfo.map(({name}) => (
                        <Item key={name}/>
                    ))
                }
            </div>
        </div>
    )
}

export default List;