import React from "react";
import styles from './Products.module.scss';
import ProductItem from "../../Common/ProductItem/ProductItem";
import Img from "../../../assets/img/slide.png";
import ProductActions from "./ProductActions/ProductActions";

const Products = () => {

    let productInfo = [
        {
            images: [Img, Img],
            code: 'А: N015',
            stock: 0,
            type: 'Двоспальний',
            content: ['Наволочка (з Вушками)', 'Підковдра (Стандарт)', 'Простирадло (на резинці)'],
            additionalOptions: ['Подарункова упаковка']
        },
        {
            images: [],
            code: 'А: N015',
            stock: 0,
            type: 'Сімейний',
            content: ['Наволочка (з Вушками)', 'Підковдра (Стандарт)', 'Простирадло (на резинці)'],
            additionalOptions: ['Подарункова упаковка']
        }, {
            name: 'Електропростинь',
            images: [],
            code: 'А: N015',
            stock: 0,
            type: '',
            content: ['Наволочка (з Вушками)', 'Підковдра (Стандарт)', 'Простирадло (на резинці)'],
            additionalOptions: ['Подарункова упаковка']
        }]

    return (
        <div className={styles.products}>
            <h2 className={styles.products__title}>Товар</h2>
            {productInfo.map((el, index) => <ProductItem item={el} key={index}/>)}
            <ProductActions/>
        </div>
    )
}

export default Products;