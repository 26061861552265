import React from "react";
import styles from './Contacts.module.scss';
import logo from "../../../assets/img/logoEgo.svg";
import ContactsContent from "./ContactsContent/ContactsContent";
import ContactsAbout from "./ContactsAbout";

const Contacts = () => {
    return (
        <div className={styles.contacts}>
            <div className={styles.contacts__logo}>
                <img src={logo} alt=""/>
            </div>
            <h2>Контакти</h2>
            <ContactsContent/>
            <ContactsAbout />
        </div>
    )
}

export default Contacts;