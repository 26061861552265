import React, {useState} from "react";
import styles from './DeliveryNP.module.scss';
import {useFormik} from "formik";
import './delivery.scss'
import NovaPoshtaCities from "./NovaPoshtaCities/NovaPoshtaCities";
import NovaPoshtaDepartments from "./NovaPoshtaDepartments/NovaPoshtaDepartments";
import NovaPoshtaWeight from "./NovaPoshtaWeight/NovaPoshtaWeight";
import DeliveryIPay from "./DeliveryIPay/DeliveryIPay";
import copyIcon from "../../../assets/icons/copy.svg";
import {CopyToClipboard} from "react-copy-to-clipboard";

const DeliveryNP = () => {
    const [city, setCity] = useState('');
    const [department, setDepartment] = useState('');
    const [weight, setWeight] = useState(null);
    const [isIPay, setIsIPay] = useState(false);

    const {setSubmitting, handleSubmit, isSubmitting, handleChange, values, resetForm} = useFormik({
        initialValues: {
            firstNameNP: '',
            lastNameNP: '',
            phoneNP: '',
            department: '',
            declaredValueNP: '',
            deliveryCostNP: '',
            ttnNP: '',
            commentNP: '',
        },

        onSubmit: (values) => {
            setSubmitting(false);
            console.log(values)
            resetForm();
        },
    });

    return (
        <div className={`${styles.deliveryNP} deliveryNP`}>
            <form onSubmit={handleSubmit}>
                <div className={styles.deliveryNP__content}>
                    <div className={styles.deliveryNP__row}>
                        <input
                            placeholder={'І`мя Отримувача'}
                            className={'baseInput'}
                            id="firstNameNP"
                            name="firstNameNP"
                            type="text"
                            onChange={handleChange}
                            value={values.firstNameNP}
                        />
                        <input
                            placeholder={'Прізвище Отримувача'}
                            className={'baseInput'}
                            id="lastNameNP"
                            name="lastNameNP"
                            type="text"
                            onChange={handleChange}
                            value={values.lastNameNP}
                        />
                    </div>
                    <div>
                        <input
                            placeholder={'Телефон Отримувача'}
                            className={'baseInput'}
                            id="phoneNP"
                            name="phoneNP"
                            type="text"
                            onChange={handleChange}
                            value={values.phoneNP}
                        />
                    </div>
                    <div className={styles.deliveryNP__row}>
                        <NovaPoshtaCities city={city} setCity={setCity} />
                        <NovaPoshtaDepartments city={city} setDepartment={setDepartment} department={department} />
                    </div>
                    <NovaPoshtaWeight weight={weight} setWeight={setWeight} />
                    <DeliveryIPay isIPay={isIPay} setIsIPay={setIsIPay} />
                    <div>
                        <input
                            placeholder={'Оголошена вартість'}
                            className={'baseInput'}
                            id="declaredValueNP"
                            name="declaredValueNP"
                            type="number"
                            onChange={handleChange}
                            value={values.declaredValueNP}
                        />
                    </div>
                    <div>
                        <input
                            placeholder={'Орієнтовна Вартість Доставки'}
                            className={'baseInput'}
                            id="deliveryCostNP"
                            name="deliveryCostNP"
                            type="number"
                            onChange={handleChange}
                            value={values.deliveryCostNP}
                        />
                    </div>
                    <div className={styles.transportNumber}>
                        <input
                            placeholder={'ТТН'}
                            className={'baseInput'}
                            id="ttnNP"
                            name="ttnNP"
                            type="number"
                            onChange={handleChange}
                            value={values.ttnNP}
                        />
                        <CopyToClipboard text={values.ttnNP} className={styles.copyButton}>
                            <button type='button'>
                                <img src={copyIcon} alt=""/>
                            </button>
                        </CopyToClipboard>
                    </div>
                    <div>
                        <textarea
                            cols="30"
                            rows="10"
                            placeholder={'Коментар'}
                            id="commentNP"
                            name="commentNP"
                            onChange={handleChange}
                            value={values.commentNP}
                        />
                    </div>
                    <div className={styles.action}>
                        <button className={'globalBtn'} type='submit'>
                            <span>Зберегти</span>
                        </button>
                    </div>
                </div>
            </form>
        </div>
    )
}

export default DeliveryNP;