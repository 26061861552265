import React from "react";
import styles from './Contacts.module.scss';


const ContactsAbout = () => {
    return (
        <div className={styles.contacts__about}>
            <h2>Про компанію</h2>
            <p>
                Використовується в друкарстві та дизайні. Lorem Ipsum є, фактично, стандартною "рибою" аж з XVI
                сторіччя, коли невідомий друкар взяв шрифтову гранку та склав на ній підбірку зразків шрифтів.
            </p>
            <p>
                Вона популяризувалась в 60-их роках минулого сторіччя завдяки виданню зразків шрифтів Letraset, які
                містили уривки з Lorem Ipsum, і вдруге - нещодавно завдяки програмам комп'ютерного верстування на
                кшталт Aldus Pagemaker, які використовували різні версії Lorem Ipsum.
            </p>
        </div>
    )
}

export default ContactsAbout;