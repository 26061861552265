import React from "react";
import {Route, Routes} from "react-router-dom";
import Main from "./Main/Main";
import MainMenu from "../Navigations/MainMenu";
import Storage from "./Storage/Storage";
import Fabrics from "./Fabrics/Fabrics";
import Order from "./Order/Order";
import Fabric from "../UserPage/Fabric";
import Products from "./Products/Products";
import DeliveryNP from "./DeliveryNP/DeliveryNP";
import DeliveryUKR from "./DeliveryUKR/DeliveryUKR";
import OrderStatus from "./OrderStatus/OrderStatus";

const AuthorizedPage = () => {
    return (
        <>
            <Routes>
                <Route index element={<Main />} />
                <Route path={'/fabrics/'} element={<Fabrics />} />
                <Route path={'/storage/'} element={<Storage />} />
                <Route path={'/order/'} element={<Order />} />
                <Route path={'/products/'} element={<Products />} />
                <Route path={'/deliveryNP/'} element={<DeliveryNP />} />
                <Route path={'/deliveryUKR/'} element={<DeliveryUKR />} />
                <Route path={'/order_status/'} element={<OrderStatus />} />

                <Route path={'/user_page/*'} element={<Fabric/>} />
            </Routes>
            <MainMenu />
        </>
    )
}

export default AuthorizedPage;