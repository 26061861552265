import React from "react";
import styles from './AttachBtn.module.scss';
import Attach from "../../../../../assets/icons/attach.svg";

const AttachButton = () => {

    return (
        <button className={styles.btn}>
            <img src={Attach} alt=""/>
        </button>
    )
}

export default AttachButton;