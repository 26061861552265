import React, {useEffect, useState} from "react";
import styles from './FabricChooserList.module.scss';


const FabricChoose = ({item, handleChange, selectedFabric}) => {
    const [isActive, setIsActive] = useState(false)

    useEffect(() => {
        if (selectedFabric && selectedFabric.length) {
            const isHasItem = selectedFabric.find((element) => element === item);

            if (isHasItem) {
                setIsActive(true);
            } else {
                setIsActive(false)
            }
        }
    }, [item, selectedFabric])


    return (
        <li className={styles.choose}>
            <input type="checkbox" checked={isActive === true} onChange={() => handleChange(item)} />
            <p>
                <span>{item}</span>
            </p>
        </li>
    )
}

export default FabricChoose;