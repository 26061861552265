import React from "react";
import styles from './DeliveryChange.module.scss';
import Button from './Button/Button';

const DeliveryChange = ({onConfirm, onCancel}) => {
    return (
        <div className={styles.delivery}>
            <h2>Ви бажаєте змінити службу доставки?</h2>
            <Button confirm action={onConfirm}/>
            <Button action={onCancel}/>
        </div>
    )
}

export default DeliveryChange;