import React from "react";
import styles from './Order.module.scss';


const UrgentlyField = ({setIsUrgently, isUrgently}) => {
    return (
        <div className={styles.urgentlyField}>
            <div className={styles.customCheckbox}>
                <input name={'urgentlyField'} type={'checkbox'} onChange={() => setIsUrgently(!isUrgently)} />
                <span/>
                <p>Терміново</p>
            </div>
        </div>
    )
}

export default UrgentlyField;