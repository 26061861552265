import React, {useState} from "react";
import styles from './Button.module.scss';
import CheckBox from "../CheckBox/CheckBox";


const PaidButton = () => {
    const [checked, setChecked] = useState(false);

    return (
        <button className={styles.btn}>
            <CheckBox name="payment_checkbox" value={checked} setChecked={setChecked}/>
        </button>
    )
}

export default PaidButton;