import React from "react";
import styles from './StoragePopup.module.scss';
import searchIcon from "../../../../assets/icons/search.svg";
import StorageItem from "./StorageItem/StorageItem";

const StoragePopup = () => {
    let storageInfo = [
        {'name': 'Ковдра', 'balance': 72, 'img': null},
        {'name': 'Подушка', 'balance': 8, 'img': null},
        {'name': 'Плед', 'balance': 60, 'img': null},
        {'name': 'Наматрасник', 'balance': 60, 'img': null},
        {'name': 'Електро-простинь', 'balance': 60, 'img': null, 'sizeCount': 7, 'colorCount': 8}
    ]

    return (
        <div className={styles.storage}>
            <div className={styles.storage__search}>
                <input type="search" placeholder={'Пошук'} className={'baseInput'}/>
                <img src={searchIcon} alt=""/>
            </div>
            {
                storageInfo.map(({name, balance, img, sizeCount, colorCount}, index) => <StorageItem key={index} name={name} balance={balance}
                                                                              img={img} sizeCount={sizeCount} colorCount={colorCount}/>)
            }
        </div>
    )
}

export default StoragePopup;