import React, {useState} from "react";
import styles from './SearchInput.module.scss';
import IconSearch from "../../../../../assets/icons/search.svg";


const SearchInput = () => {
    const invoiceNumber = 'TTH 821652163126375217631276' || 'ТТН';

    return (
        <label className={styles['search-wrapper']}>
            <input type="text" name="invoice-number" value={invoiceNumber} className="search-input" autoCorrect="off"
                   spellCheck="false" placeholder="ТТН" disabled/>
            <img src={IconSearch} alt=""/>
        </label>
    )
}

export default SearchInput;