import React from "react";
import styles from './OrderInfoBlock.module.scss';
import {CopyToClipboard} from "react-copy-to-clipboard";
import copyIcon from "../../../assets/icons/copy2.svg";

const OrderInfoBlock = ({invoice, link, post}) => {

    return (
        <div className={styles.order}>
            <a href={link} className={styles.order__instaLink}>інстаграм посилання</a>
            <p>{post}</p>
            <p className={styles.order__invoice}>
                <span>{invoice}</span>
                <CopyToClipboard text={invoice}>
                    <button>
                        <img src={copyIcon} alt=""/>
                    </button>
                </CopyToClipboard>
            </p>
        </div>
    )
}

export default OrderInfoBlock;