import React, {useState} from "react";
import styles from './Order.module.scss';
import arrow from "../../../assets/icons/arrowDownGray.svg";
import {useAppDispatch} from "../../../hooks/redux";
import {setIsActivePopup} from "../../../store/main-slice";
import GlobalPopup from "../../Common/GlobalPopup/GlobalPopup";
import PopupCheckboxes from "../../Common/PopupCheckboxes/PopupCheckboxes";

const StatusPay = ({setPopup, popup}) => {
    const dispatch = useAppDispatch();
    const [status, setStatus] = useState('');

    const chooseList = [
        'Оплачено', 'В обробці', 'Очікує оплати', 'Не оплачено'
    ];

    const OnButton = (value) => {
        setPopup(value)
        setTimeout(() => {
            dispatch(setIsActivePopup(true))
        }, 30)
    }

    return (
        <>
            <button type='button' className={styles.statusPay} onClick={() => OnButton('statusPay')}>
                <span>Статус оплати</span>
                <img src={arrow} alt=""/>
            </button>
            {popup && popup === 'statusPay' && (
                <GlobalPopup>
                    <PopupCheckboxes values={status} setValues={setStatus} chooseList={chooseList} isRadio />
                </GlobalPopup>
            )}
        </>
    )
}

export default StatusPay;